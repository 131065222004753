import { clientTranslationsType } from '@/clients/translations/client-translations-type';

export const clientTranslations: clientTranslationsType = {
    classrooms: 'programs',
    Classrooms: 'Programs',
    classroom: 'program',
    Classroom: 'Program',
    classes: 'programs',
    Classes: 'Programs',
    class: 'program',
    Class: 'Program'
};
